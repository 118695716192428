import { useRouteError } from 'react-router-dom';
import '../cover/cover.style.scss';
import './error.style.scss';

const ErrorPage = () => {


    const errorInfo = useRouteError();


    const ui = <section className="section landing-cover">

        <div className="row cover-wrap">

            <div className="col-md-12 half-cover landing-left-half">

                <div className="half-wrap">

                    <h1 className='error-icon'>
                        <i className="fa-solid fa-person-hiking"></i>
                    </h1>


                    <h2 className="text-segment-1 cover-text">
                        Looks like you are lost!
                    </h2>

                    <p className="text-segment-3 cover-text-intro">
                        Error: {errorInfo.statusText || errorInfo.message}
                    </p>

                    <button className='z-btn'>Take me Home</button>

                </div>

            </div>
        </div>

    </section>;

    return ui;

}

export default ErrorPage;