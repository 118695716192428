import './_icon-button.scss';

const IconButton = ({ text, icon, icon_color, onClick, ikey }) => {

    const onButtonClick = () => {
        if (onClick) {
            onClick()
        }
    }

    const ui = <button className='z-btn z-icon-btn cover-links' onClick={onButtonClick} key={ikey}>
        <div>
            <i className={icon} style={{ color: icon_color }}></i>
            {text}
        </div>
    </button>;

    return ui;

}


export default IconButton;