import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';

import './themes/css/normalize.css';

import 'bootstrap/dist/css/bootstrap-grid.css';

// theming
//light theme
import './themes/light.theme.css'
//dark theme
import './themes/dark.theme.css'
import DataStore from './data/data';
// import LandingSection from './components/landing/landing.component';
import { RouterProvider } from 'react-router-dom';
import ApplicationRouter from './router/router.config';


document.title = DataStore.html_title;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={ApplicationRouter}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
