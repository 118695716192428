
// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataStore from '../../data/data';
import './cover.style.scss';
import IconButton from './ui-kit/icon-button';
import AppRoutes from '../../router/paths';

const LandingCover = () => {

    const T = DataStore.cover;

    const routerNavigate = useNavigate()

    // const [darkTheme, setDarkTheme] = useState(false);

    /**
     * Toggle the theme
     */
    /*
    const toggleTheme = () => {
        if (darkTheme) {
            document.documentElement.setAttribute('data-theme', '')
            setDarkTheme(false);
        } else {
            document.documentElement.setAttribute('data-theme', 'dark')
            setDarkTheme(true);
        }
    }
    */

    const onCoverLinkClick = (config) => {
        if(config.type==='external'){
            window.open(config.link,'_blank')
        }
    }


    const onLearnMoreClick=()=>{
        routerNavigate(AppRoutes.ABOUT)
    }


    const ui = <section className="section landing-cover">

        <div className="row cover-wrap">

            <div className="col-md-8 half-cover landing-left-half">

                <div className="half-wrap">
                    {/* left half */}

                    <h2 className="text-segment-1 cover-text">
                        {T.header1}
                    </h2>
                    <h1 className="text-segment-2 cover-text-name">
                        {T.header_name}
                    </h1>

                    <p className="text-segment-3 cover-text-intro">
                        {T.cover_intro2}
                    </p>

                    {/* <button className='z-btn' onClick={onLearnMoreClick}>Learn More</button> */}

                </div>

            </div>
            <div className="col-md-4 half-cover landing-right-half">

                <div className="half-wrap">
                    {
                        T.cover_links.map((btn_config,i) => {
                            if (btn_config.active)
                                return <IconButton
                                    key={`key-${i}`}
                                    icon={btn_config.icon}
                                    icon_color={btn_config.icon_color}
                                    text={btn_config.text}
                                    onClick={() => { onCoverLinkClick(btn_config) }}
                                />
                            return <></>
                        })
                    }
                </div>

            </div>

        </div>

    </section>;

    return ui;

}


export default LandingCover;