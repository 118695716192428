
import { useNavigate } from 'react-router-dom';
import DataStore from '../../data/data';
import '../cover/cover.style.scss';
import IconButton from '../cover/ui-kit/icon-button';
import './about.style.scss';
import { useEffect } from 'react';


const AboutPage = () => {

    const T = DataStore.about

    const routerNavigate = useNavigate()

    useEffect(()=>{
        document.title = 'About - Bharath'
    })


    const onAboutLinksClick = (config) => {
        if(config.type==='internal'){
        routerNavigate(config.link);
        }
    }


    const ui = <section className="section landing-cover">

        <div className="row cover-wrap">

            <div className="col-md-8 half-cover landing-left-half">

                <div className="half-wrap">

                    <h2 className="text-segment-1 cover-text">
                        {T.header1}
                    </h2>

                    <p className="text-segment-3 cover-text-intro">
                        {T.about_info}
                    </p>

                    <p className="text-segment-3 cover-text-intro">
                        {T.about_info}
                    </p>


                </div>

            </div>
            <div className="col-md-4 half-cover landing-right-half">

                <div className="half-wrap">
                    {
                        T.about_links.map((btn_config, i) => {
                            if (btn_config.active)
                                return <IconButton
                                    key={`key-${i}`}
                                    icon={btn_config.icon}
                                    icon_color={btn_config.icon_color}
                                    text={btn_config.text}
                                    onClick={() => { onAboutLinksClick(btn_config) }}
                                />
                            return <></>
                        })
                    }
                </div>

            </div>

        </div>

    </section>;


    return ui;

}

export default AboutPage;