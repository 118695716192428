const DataStore = {

    html_title: 'Welcome to my portfolio - Bharath Chinnam',

    cover: {
        header1: 'Hi! I\'m',
        header_name: 'Bharath',
        cover_intro: '    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

        cover_intro2: "I\'m a freelance designer & developer. I primarily focus on designing & developing scalable and fault-tolerant software systems. I have experience in handling end-to-end software development. Over the years, I worked with clients in banking, health and retail domains. I currently work at Citi. ",

        cover_links: [
            {
                text: 'LinkedIn',
                icon: 'fa-brands fa-linkedin',
                icon_color: '#1c71d8',
                btn_color: 'white',
                type: 'external',
                link: 'https://linkedin.com/in/chinnambhrt',
                active: true
            },
            {
                text: 'Github',
                icon: 'fa-brands fa-github',
                icon_color: 'black',
                btn_color: 'white',
                type: 'external',
                link: 'https://github.com/chinnambhrt',
                active: true,
            },
            {
                text: 'Resume',
                icon: 'fa-regular fa-file-lines',
                icon_color: 'black',
                btn_color: 'white',
                active: false
            },
            {
                text: 'Skills',
                icon: 'fa-solid fa-toolbox',
                icon_color: '#c44e17',
                btn_color: 'white',
                active: false
            },
        ]
    },

    about: {
        about_links: [
            {
                text: 'Home',
                icon: 'fa-solid fa-house',
                type: 'internal',
                link: '/',
                active: true
            },

        ],

        header1: 'About Me',
        about_info: 'Thanks!     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

    }

}

export default DataStore;