import { createHashRouter } from "react-router-dom";
import LandingCover from "../components/cover/cover.component";
import AboutPage from "../components/about/about";
import ErrorPage from "../components/error/error";
import AppRoutes from "./paths";


const ROUTER_CONFIG = [
    {
        path:AppRoutes.HOME,
        element: <LandingCover/>,
        errorElement:<ErrorPage/>
    },
    {
        path:AppRoutes.ABOUT,
        element: <AboutPage/>
    },
];


const ApplicationRouter = createHashRouter(ROUTER_CONFIG);

export default ApplicationRouter;